<template>
    <div>
        <titulo-contratos />
        <b-row>
            <b-col md="3" v-if="opcoes?.alunosVinculados?.length > 0">
                <input-select-search
                    ref="matriculaId"
                    :options="opcoes.alunosVinculados"
                    :label="$t('ACADEMICO.ALUNO')"
                    v-model="filtro.matriculaId"
                    required
                />
            </b-col>
            <b-col md="4">
                <input-select-search
                    ref="anoLetivo"
                    :label="$t('ACADEMICO.ANO_LETIVO')"
                    v-model="filtro.anoLetivoId"
                    :options="opcoes.anosLetivos"
                    class="w-100"
                    required
                />
            </b-col>
            <b-col md="auto">
                <b-button
                    class="botao-acao-filtro w-100 mb-3"
                    variant="primary"
                    @click="pegarContratosPorMatriculaIdEAnoLetivoId"
                >
                    {{ $t('GERAL.FILTRAR') }}
                </b-button>
            </b-col>
            <b-col md="auto">
                <b-button
                    class="botao-acao-filtro w-100 mb-3"
                    variant="secondary"
                    @click="limpar"
                >
                    {{ $t('GERAL.LIMPAR') }}
                </b-button>
            </b-col>
        </b-row>
        <tabela-contratos :items="items" />
        <paginacao
            v-model="paginacao"
            :total="paginacao.total"
            @input="pegarContratosPorMatriculaIdEAnoLetivoId"
        />
    </div>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import JwtService from '@/common/utils/jwt';

//Services
import ContratoService from '@/common/services/contratos/contratos.service';

// Components:
import TabelaContratos from '@/views/financeiro/meus-contratos/components/TabelaContratos';
import TituloContratos from '@/views/financeiro/meus-contratos/components/TituloContratos';
import Paginacao from '@/components/paginacao/Paginacao';
import { InputSelectSearch } from '@/components/inputs';

export default {
    components: {
        TituloContratos,
        TabelaContratos,
        InputSelectSearch,
        Paginacao,
    },
    data() {
        return {
            filtro: {
                anoLetivoId: null,
                matriculaId: null,
            },
            opcoes: {
                alunosVinculados: [],
                anosLetivos: [],
            },
            items: [],
            paginacao: {
                total: 0,
                numeroDaPagina: 1,
                tamanhoDaPagina: 10,
            },
        };
    },
    mounted() {
        this.getAlunosVinculadosResponsavel();
    },
    watch: {
        'filtro.matriculaId': {
            handler(value) {
                if (value) {
                    this.getAnosLetivosDoContrato();
                    this.filtro = {
                        ...this.filtro,
                        anoLetivo: null,
                    };
                    this.items = [];
                    this.paginacao = {
                        total: 0,
                        numeroDaPagina: 1,
                        tamanhoDaPagina: 10,
                    };
                }
            },
            immediate: true,
        },
        'opcoes.alunosVinculados': {
            handler(value) {
                if (value?.length > 0) {
                    this.filtro.matriculaId = null;
                    return;
                }
                this.filtro.matriculaId = JwtService.buscarMatricula();
            },
            immediate: true,
        },
    },
    methods: {
        getAlunosVinculadosResponsavel() {
            this.opcoes.alunosVinculados =
                JwtService.buscarAlunosVinculados().reduce(
                    (alunosSemDuplicatas, aluno) => {
                        const existe = alunosSemDuplicatas.some(
                            (a) => a.value === aluno.matriculaId
                        );
                        if (!existe) {
                            alunosSemDuplicatas.push({
                                text: aluno.nome,
                                value: aluno.matriculaId,
                            });
                        }
                        return alunosSemDuplicatas;
                    },
                    []
                );
        },

        getAnosLetivosDoContrato() {
            if (!this.filtro?.matriculaId) return;
            this.$store.dispatch(START_LOADING);
            ContratoService.buscarPorMatricula(this.filtro.matriculaId, 1000, 1)
                .then(({ data }) => {
                    data = data.itens
                        .reduce((anosLetivosUnicos, contratoAtual) => {
                            if (
                                !anosLetivosUnicos.some(
                                    (ano) =>
                                        ano.value === contratoAtual.anoLetivoId
                                )
                            ) {
                                anosLetivosUnicos.push({
                                    value: contratoAtual.anoLetivoId,
                                    text: contratoAtual.anoLetivoDescricao.toString(),
                                });
                            }
                            return anosLetivosUnicos;
                        }, [])
                        .sort((a, b) => a.text - b.text);

                    this.opcoes.anosLetivos =
                        data.length > 0
                            ? data
                            : [
                                  {
                                      text: this.$t(
                                          'ACADEMICO.NENHUM_ANO_LETIVO'
                                      ),
                                      value: null,
                                  },
                              ];
                })
                .catch((err) => {
                    mensagem.showErrors(err);
                })
                .finally(() => this.$store.dispatch(STOP_LOADING));
        },

        pegarContratosPorMatriculaIdEAnoLetivoId() {
            this.$store.dispatch(START_LOADING);
            ContratoService.pegarContratosPorMatriculaIdEAnoLetivoId({
                paginacao: this.paginacao,
                ...this.filtro,
            })
                .then((result) => {
                    this.items = result.data.itens;
                    this.paginacao.total =
                        result.data.paginacao.totalDeElementos;
                })
                .catch((err) => {
                    mensagem.showErrors(err);
                })
                .finally(() => {
                    this.$store.dispatch(STOP_LOADING);
                });
        },

        limpar() {
            this.filtro = {
                matriculaId: null,
                anoLetivo: null,
            };
            this.items = [];
            this.paginacao = {
                total: 0,
                numeroDaPagina: 1,
                tamanhoDaPagina: 10,
            };
        },
    },
};
</script>
