<template>
    <b-table
        :fields="fields"
        :items="items"
        :empty-text="$t('GERAL.NENHUM_REGISTRO')"
        head-variant="light"
        stacked="md"
        show-empty
        responsive
        striped
        hover
    >
        <template #cell(acoes)="item">
            <b-dropdown variant="outline-default">
                <b-dropdown-item @click="baixar(item.item)">
                    {{ $t("GERAL.BAIXAR") }}
                </b-dropdown-item>
                <b-dropdown-item @click="visualizar(item.item.id)">
                    {{ $t("GERAL.VISUALIZAR") }}
                </b-dropdown-item>
            </b-dropdown>
        </template>
    </b-table>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from "@/store/Store"
import pdf from "@/common/utils/pdf"
import mensagem from "@/common/utils/mensagem"
import helpers from "@/common/utils/helpers"
// import rotas from '@/common/utils/rotas'
import ContratoService from "@/common/services/contratos/contratos.service"

export default {
    props: {
        items: { type: Array, default: Array },
    },
    data() {
        return {
            fields: [
                {
                    key: "codigo",
                    label: this.$t("FINANCEIRO.CODIGO"),
                    sortable: true,
                },
                {
                    key: "tipoContrato",
                    label: this.$t("FINANCEIRO.TIPO_CONTRATO"),
                    sortable: true,
                },
                {
                    key: "ano",
                    label: this.$t("FINANCEIRO.ANO_LETIVO"),
                    sortable: true,
                },
                {
                    key: "dataGeracao",
                    label: this.$t("FINANCEIRO.DATA_GERACAO"),
                    formatter: (value) => {
                        return value ? helpers.formatarDataBr(value) : "-"
                    },
                    sortable: true,
                },
                {
                    key: "statusContratoDescricao",
                    label: this.$t("FINANCEIRO.STATUS_CONTRATO"),
                    sortable: true,
                },
                {
                    key: "acoes",
                    label: this.$t("GERAL.ACOES"),
                },
            ],
        }
    },
    methods: {
        baixar(item) {
            this.$store.dispatch(START_LOADING)
            ContratoService.baixarPDF(item.id)
                .then(({ data }) => {
                    if (data.documento) {
                        //converte os bytes em array:
                        let bytes = pdf.base64ToArrayBuffer(data.documento)
                        // converte o array em blob
                        let blob = new Blob([bytes], {
                            type: "application/pdf",
                        })
                        // faz download do PDF:
                        var link = document.createElement("a")
                        link.href = window.URL.createObjectURL(blob)
                        link.download = data.nomeDocumento
                        link.click()
                    }
                })
                .catch((err) => {
                    mensagem.showErrors(err)
                })
                .finally(() => {
                    this.$store.dispatch(STOP_LOADING)
                })
        },
        visualizar(id) {
            return this.$router.push({ name: 'visualizar-contrato', params: { id } })
            // rotas.irPara("visualizar-contrato", id)
        },
    },
}
</script>
